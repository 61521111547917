/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$mainfont: "canada-type-gibson";
$secondaryfont: "proxima-nova";
$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #6a327c; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu
$themecolor6: #535E48; //footer

$menudividerlight: #743a87;
$menudividerdark: #572767;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;
$buttoncolor3: #F7951F;

$titlecolor1: #000000;
$titlecolor2: #227093;


/* ========= Dynamic color ========= */
:root { --primarycolor: #fff; --selectedcolor: #fff; --hovercolor: #fff; }

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;

$textcolor: #A7A7A7;
$textcolordark: #767676;
$greybuttonbg: #F0F0F0;
$greybutton: #9C9C9C;


// Global styles
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}
h1 {font-size: 20px;font-weight: 700;font-family: $mainfont, sans-serif;color: $titlecolor1;}

// Body and Container
.mat-drawer-container {background: #ffffff;background-size: cover;}
.container {height: 100vh;}

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }


// Main Header
// ===========
.header {

    & { background-color: #fff; position: fixed;z-index: 1000;}

    .mat-toolbar-row {color: #fff; height: 60px; padding: 0 30px 0 10px; }
    .closebtn {display: block;background: $themeaccentcolor1;color: #fff;font-size: 24px;width: 20px;text-align: center;margin: 10px 60px 10px -16px;height: 30px;padding-top: 8px;transition: width 1s ease;}
    .menubtn {display: block; font-size: 28px; width: 50px; text-align: center; transition: width 1s ease;}

    //.menu {display: block;color: #fff;font-size: 0.85em;text-transform: uppercase;font-family: $mainfont;padding: 16px 30px 9px;font-weight: 800;text-decoration: none;border-bottom: 5px solid $themecolor1;}
    .active { border-bottom: 7px solid #fff; }
    .logo img { width: 100px; margin-top: 5px; margin-left: 10px; }

}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important;}
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {text-transform: uppercase;font-family: $mainfont;}
.mat-tab-labels {background: #fff;}



.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

/* -- Menu Main -- */
/* -- ========= -- */
.mainmenu {

    & {width: 300px; color: #fff;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    .container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 120px; color: var(--primarycolor);text-decoration: none; font-weight: 600; background: none; }
    nav .profile { padding: 20px;text-align: center;font-size: 1.6em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 60px;border-radius: 50%;background: var(--primarycolor); font-weight: 600; justify-content: center; align-items: center; }
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px; font-weight: 500; color: var(--primarycolor); }
    nav .profile .profiledetails span {font-size: 12px;}
    nav .profile .profiledetails input { height: auto; border: none; padding: 0; }
    nav .submenu { font-size: 0.9em; text-transform: uppercase; border-bottom: 2px solid var(--primarycolor); margin-left: 20px; margin-bottom: 15px; font-weight: 500; cursor: pointer; }
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}

}






// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

    & {font-family: $mainfont;}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: transparent;z-index: 1000; }
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
               background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; }

.lightbox {
    & { position: fixed; text-align: center; padding: 0px; background: #FFF;
        z-index: 10001; border-radius: 9px; min-height: 100px; top: 64px; font-family: $mainfont; }

    h1 { color: #FFF; background-color: #16577f; font-size: 1.5em; margin: 0px;
         padding: 20px 0px; border-top-right-radius: 7px; border-top-left-radius: 7px; }
}

.defaultLightbox > div {
    padding: 10px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
}

.login {

    & {min-width: 30vw;margin-top: 100px;font-family: $mainfont;background: $themecolor4;padding: 30px;}
    .loginheader {margin: -30px -30px 0 -30px;background: $themecolor1;padding: 20px;text-align: center;}
    .loginheader img {margin: 0 auto;width: 200px;}
    div.actionBtn { display: flex; justify-content: flex-end; }
    .mat-form-field {background: $themecolor4;}
    form {
        input[type="text"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="email"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"] { height: 16px; padding: 0px; border: 0; background: none; border-radius: 0px; }
        input[type="password"]:focus { border: none; outline: none; }
    }
}

// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

/* ========= global styles =========== */
.mat-toolbar-multiple-rows { min-height: 60px !important; }
span.icon-bar { display: block; height: 2px; margin: 2px auto; transition: .4s; background: #535E48; width: 14px; }
.header-space { margin-top: 80px; }
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}
.calendarspinner { min-height: calc(100vh - 100px); }
// alignments
.mobile-frame { max-width: 600px; margin: 0 auto; width: 100%; }
.mobile-frame-padding { padding: 0px 15px; width: calc(100% - 30px); }
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }
.flex { display:flex; flex-wrap: wrap; }
.absolute { position:absolute; }
.relative { position:relative; }
.fixed { position:fixed; }
.full-width { width:100%; }
.float-left { float:left; }
.space-5 { margin-top:5px; }
.space-10 { margin-top:10px; }
.space-15 { margin-top:15px; }
.space-20 { margin-top:20px; }
.space-30 { margin-top:30px; }
.space-40 { margin-top:40px; }
.space-50 { margin-top:50px; }
//colors
.bg-white { background-color: #ffffff; }
.color-white { color: #ffffff;  }
.color-black { color: #000000;  }
.bg-color1 { background-color: $themecolor1; }
.color1 { color: $themecolor1; }
.text-color { color: $textcolor; }
.text-color-dark { color: $textcolordark }

// tick box svg
.checkmark__circle { stroke-dasharray: 166; stroke-dashoffset: 166; stroke-width: 2; stroke-miterlimit: 10; stroke: var(--hovercolor); fill: none; animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }
.checkmark { width: 50px; height: 50px; border-radius: 50%; display: block; stroke-width: 2; stroke: #fff; stroke-miterlimit: 10; box-shadow: inset 0px 0px 0px var(--hovercolor); animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; }
.checkmark__check { transform-origin: 50% 50%; stroke-dasharray: 48; stroke-dashoffset: 48; animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }
@keyframes stroke {
  100% { stroke-dashoffset: 0; }
}
@keyframes scale {
  0%, 100% { transform: none; }
  50% { transform: scale3d(1.1, 1.1, 1); }
}
@keyframes fill {
  100% { box-shadow: inset 0px 0px 0px 30px var(--hovercolor); }
}

//fonts
.mainfont { font-family:$mainfont; }
.secondaryfont { font-family:$secondaryfont; }
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }
//buttons
.circle-btn { font-size: 20px; text-transform: uppercase; padding: 5px 15px !important; min-width: 200px !important; height: 54px; border-radius: 40px !important; font-weight: 600; cursor: pointer !important; line-height: normal !important; display: inline-flex !important; justify-content: center; align-items: center; transition: 0.4s; }
.square-btn { font-size: 14px; text-transform: uppercase; padding: 5px 50px !important; min-width: 200px !important; height: 40px; border-radius: 8px !important; font-weight: 600; cursor: pointer !important; line-height: normal !important; display: inline-flex !important; justify-content: flex-start; align-items: center; }
.buttoncolor3 { background-color: $buttoncolor3; color:#fff; }
.buttoncolor3:disabled { background-color: $buttoncolor3 !important; opacity:0.8; color:#fff !important; }
.buttoncolor3:hover { color:#fff; }
.buttongrey { background-color: $greybuttonbg; color:$greybutton; }
.buttongrey:disabled { background-color: $greybuttonbg !important; opacity:0.8; color:$greybutton !important; }
.buttongrey:hover { color:$greybutton; }
.submitdisabled:disabled { background-color: $greybuttonbg !important; opacity:0.8; color:#a9a9a9 !important; }
//borders
.border-1px { border-width:1px; }
.border-2px { border-width:2px; }
.border-dashed { border-style: dashed; }
.border-solid { border-style: solid; }
.border-dotted { border-style: dotted; }

// Form field
.mat-form-field { font-family: $mainfont; width: 100%; }
.mat-form-field-appearance-outline .mat-form-field-infix { padding: 12px 0 15px 0; }
input[type="password"] { height: 22px; padding: 0px; letter-spacing: 0.1em; background-color: transparent; border: none !important; border-radius: 0px; }
input::placeholder { color: #9f6caf !important; }
// checkbox
.mat-checkbox { width: 100%; display: block; margin-bottom: 5px; border-radius: 8px; }
.mat-checkbox .mat-checkbox-layout { margin-bottom: 0px; width: calc(100% - 30px); padding: 8px 15px; white-space: break-spaces; }
.mat-checkbox .mat-checkbox-inner-container { margin-left: 0px; height: 12px; width:12px; }
.mat-checkbox .mat-checkbox-background, .mat-checkbox .mat-checkbox-frame { border-radius: 4px; border-width: 1px; }
.mat-checkbox .mat-checkbox-frame { background-color: #fff; }
.mat-checkbox .mat-checkbox-layout .mat-checkbox-label { font-weight: 300; font-size: 14px; }
.mat-checkbox .mat-checkbox-layout .mat-checkbox-label strong{ font-weight: 500; }

/* ========= register page =========== */
.register {
  & { height: 100vh; }
  .logo img { width: 280px; }
  .logo { padding: 30px 0px 0px; }
  .content { padding:0px 0px 30px; }
  .heading { font-weight: 700; font-size: 28px; text-transform: capitalize; line-height: 1.2em; margin-bottom: 20px; }
  .para { font-size: 21px; line-height: 1.5; font-weight: 400; }
  .form-title { font-size: 18px; margin-bottom: 10px; }
  .register-top { height: 75%; }
  .register-top .mobile-frame { height:100%; }
  .register-form form { margin-bottom: 0px; }
  .complete-registration { height: auto; padding-bottom: 20px; }
  .register-bottom { padding-top: 15px; padding-bottom: 30px; height: calc(25% - 45px); }
  .quote { color: #84A43E; font-size: 14px; font-weight: 200; }
  .quote strong { font-weight: 400; }
  .bottom-logo { margin-top: 40px;  }
  .mat-form-field-appearance-outline .mat-form-field-outline { background-color: #F7951F0F; }
  .mat-form-field-infix { text-align: center; }
  .mat-form-field-appearance-outline .mat-form-field-outline-start { border-radius: 10px 0 0 10px !important; }
  .mat-form-field-appearance-outline .mat-form-field-outline-end { border-radius: 0 10px 10px 0 !important; }
  .mat-form-field-appearance-outline .mat-form-field-outline { color: #FFFFFF; }
  .mat-form-field input.mat-input-element { color: #fff; }
  .mat-form-field .mat-input-element::placeholder { color: #9f6caf; }
  .mat-progress-spinner circle, .mat-spinner circle { stroke: #fff; }
  .submit-loader { width: 30px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
  .circle-btn.loading .btn-text { display:none; }
  .circle-btn.loading { min-width: 54px !important; width: 54px; height: 54px; }
  .content-and-form { display: flex; flex-direction: column; justify-content: center; height: calc(100% - 105px); }
  .action-btn-link { font-size: 18px; margin-top: 10px; }
  .action-btn-link.forgot-password { margin-top: 5px; }
  .action-btn-link a { color: #fff; text-decoration: none; }

  // login page
  &.login-page {
    .register-form { display: flex; flex-direction: column; justify-content: center; align-items: center; height: calc(100% - 135px); }
  }

}

/* =========================== jumpstart css ===================== */

/* ========= calendar page =========== */
.calender-page {

  // header
  .header-date {
    & { background-color: var(--hovercolor); color: #fff; text-align: center; padding: 15px 15px 15px; line-height: normal; border-radius: 0px 0px 5px 5px; font-family:$mainfont; position: fixed; right: 25px; top: 0px; z-index: 1000; }
    .header-month { font-size: 12px; text-transform: uppercase; }
    .header-year { font-weight: 600; font-size: 20px; }
  }

  // task wrapper
  .task-wrapper {
    .task-title { font-size: 20px; font-weight: 600; color:var(--primarycolor); line-height: normal; }
    .task-content { color: #909090; font-size: 15px; font-weight: 500; }
  }

  // date wrapper
  .dates-wrapper {
    & { display: flex; flex-wrap: wrap; margin-top: 20px; }
    .date-block { flex: 0 0 14.2%; display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 8px 0px; position: relative; }
    .date-block-wrapper { border-radius: 100%; width: 50px; height: 50px; border:1px solid var(--selectedcolor); margin: 0 auto; display: flex; flex-direction: column; align-items: center; justify-content: center; color: var(--primarycolor); line-height: normal; cursor: pointer; transition: 0.4s; }
    .tasks-wrapper {
      & { position: absolute; background-color: var(--primarycolor); color: #fff; border-radius: 20px; width: 0px; visibility:hidden; opacity:0; height:0px; transition: 0.4s opacity; overflow: hidden; }
      .mat-checkbox .mat-checkbox-layout { width: 100%; padding: 0px; }
      .mat-checkbox .mat-checkbox-layout .mat-checkbox-label { font-family: $mainfont; font-size: 12px; font-weight: 200; }
      .mat-checkbox .mat-checkbox-frame, .mat-checkbox .mat-checkbox-background { border-radius: 100%; border-width: 0px; }
      .mat-checkbox { margin-bottom: 6px; }
      .mat-checkbox:last-child { margin-bottom:0px; }
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: var(--hovercolor); }
    .date-block:hover .tasks-wrapper { padding: 10px 20px; visibility:visible; opacity:1; height:auto; top: 60px; z-index: 99; width: 150px; }
    .date-block:after { bottom: -10px; left: 50%; border: solid transparent; content: ""; position: absolute; border-color: rgba(136, 183, 213, 0); border-bottom-color: var(--primarycolor); border-width: 10px; transform: translate(-50%, 0px); visibility:hidden; opacity:0; height:0px; transition: 0.4s opacity; }
    .date-block.additional-tasks:hover:after { visibility: visible; opacity: 1; height: auto; }
    .day { text-transform: uppercase; font-size: 10px; font-weight: 500; }
    .date { font-size: 15px; font-weight: 500; }
    // type - future date
    .future-date { opacity: 0.5; pointer-events: none; }
    .future-date .date-block-wrapper { border-style:dashed; }
    // type - completed
    .completed {
      // & { pointer-events: none; }
      .date-block-wrapper { background-color: var(--selectedcolor); color: #fff;position: relative; }
      .day { font-size: 8px; font-weight: 400; }
      .date { font-size: 10px; font-weight: 400; }
      .day-date { position: absolute; left: 10px; top: 8px; text-align: center; }
      .material-icons { font-size: 36px; margin-top: 12px; }
    }
    // type - just-completed
    .just-completed {
      & { pointer-events: none; }
      .date-block-wrapper { color: #fff; border-color: var(--hovercolor); background-color: var(--hovercolor); }
      span.material-icons { font-size: 36px; font-weight: 600; margin-top: 0px; }
    }
  }

  // total task
  .total-task-block {
    & { margin-top: 20px; color:#909090; font-size: 16px; font-weight: 500; }
    .task-done { font-size: 30px; font-weight: 600; }
  }

  // download pdf
  .download-pdf-wrapper {
    & { margin-top: 50px; }
    .download-pdf-content { color: #909090; font-weight: 200; font-size: 16px; }
    .download-pdf-btn { color: #C13B2E; font-weight: 600; margin: 20px 0px; cursor: pointer; }
    .download-pdf-btn a { color: #C13B2E; }
  }

}

/* ========= footer =========== */
.footer {
  & { background-color:var(--primarycolor); color:#fff; }
  .footer-padding { padding: 15px 20px 5px; width: calc(100% - 40px);  }
  .footer-wrapper { display: flex; flex-wrap: wrap; margin-bottom: 10px; }
  .footer-left { flex: 0 0 60%; }
  .footer-left img { width:100%; max-width: 200px; }
  .footer-right { flex: 0 0 40%; text-align: right; font-size: 14px; font-weight: 200; line-height: 16px; }
  .footer-copyright { text-align: center; font-size: 12px; font-weight: 200; }
  .footer-copyright a { color:#fff; text-decoration:none; letter-spacing: 1px; }
}

@media only screen and (max-width: 600px) {
  // css from thrive
  .register .logo { padding: 15px 0px 0px; }
  .register .logo img { width: 180px; }
  .register .content { padding: 0px 0px 15px; }
  .register .register-form { padding-bottom: 30px; }
  .register .bottom-logo { margin-top: 15px; }
  .bottom-logo img { width: 150px; }
  .register .register-bottom { padding-bottom: 15px; }
  .register .heading { font-size: 24px; }
  .register .para { font-size: 18px; }
  .register .circle-btn { font-size: 18px; height: 50px; }
  .register.login-page .register-form, .register .content-and-form { height: calc(100% - 66px); }
  // footer
  .footer .footer-right { font-size: 12px; line-height: 14px; }
  .footer .footer-copyright { font-size: 10px; }
}

@media only screen and (max-width: 480px) {
  // css from thrive
  .register .logo { padding: 15px 0px 10px; }
  .register .content { padding: 0px 0px 10px; }
  .register .heading { font-size: 6vw;}
  .register .para { font-size: 4vw; }
  .register .form-title { font-size: 16px; margin-bottom: 5px; }
  .register .register-form { padding-bottom: 20px; }
  .bottom-logo img { width: 125px; }
  .register .circle-btn { font-size: 16px; height: 45px; min-width: 175px !important; }
  .register .action-btn-link { font-size: 16px; }
  // calender page
  .calender-page .dates-wrapper .date-block { flex: 0 0 25%; }
}

@media only screen and (max-width: 375px) {

}

@media only screen and (max-width: 320px) {
  .calender-page .dates-wrapper .date-block { flex: 0 0 33.33%; }
}


